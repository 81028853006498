import { TrophyFilled } from '@ant-design/icons';
import { Button, Col, Divider, Row, Select, Typography, notification } from 'antd';
import { Footer } from 'antd/es/layout/layout';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import http from '../../http-common';
import Loading from '../Loading';

export default function ChoosingBooth({ sponsorName, sponsorTier, setActiveTab }) {
  const user = useSelector((state) => state.auth);
  const [selectedValues, setSelectedValues] = useState({});
  const [allOptions, setAllOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const sendError = (errorMsg) => {
    api.error({
      message: errorMsg,
      placement: 'topRight',
    });
  };

  const boothArray = Array(user['Number of Booths'] || 1).fill();
  sponsorTier = sponsorTier ? sponsorTier[0] : '';
  sponsorTier = sponsorTier.replace(' Sponsor', '');

  const handleChange = (value, index) => {
    let newSelectedValues = { ...selectedValues };
    newSelectedValues[index] = value;
    setSelectedValues(newSelectedValues);
    const allValues = Object.values(newSelectedValues);
    let newOptions = allOptions.filter((option) => !allValues.includes(option.value));
    setOptions(newOptions);
  };
  useEffect(() => {
    setIsLoading(true);
    http
      .get('api/airtable/booths')
      .then((response) => {
        setAllOptions(response.data);
        setOptions(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  const submit = () => {
    setIsLoading(true);

    const booths = Object.values(selectedValues);
    if (booths.length < boothArray.length) {
      sendError('Please Choose All booths');
      setIsLoading(false);
      return;
    }
    http
      .post('api/airtable/booths', { booths })
      .then((response) => {
        window.location.href = '/';
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response.status === 409) {
          sendError(error.response.data);
        } else sendError('Something went wrong. Please refresh the page and try again!');
      });
  };
  if (isLoading) return <Loading />;

  return (
    <>
      {contextHolder}
      <Row style={{ marginBottom: '20px', textTransform: 'capitalize' }}>
        <Typography.Title level={4}>{sponsorName}</Typography.Title>
        <Typography.Text className="tier tier-platinum">
          <TrophyFilled /> {sponsorTier}
        </Typography.Text>
      </Row>
      {boothArray.map((booth, index) => {
        return (
          <div key={index}>
            <Row align="middle" justify="space-between">
              <Col>
                <Typography.Text strong>10x10 Show Booth</Typography.Text>
              </Col>
              <Col>
                <Select
                  onChange={(value) => {
                    handleChange(value, index);
                  }}
                  placeholder="Select"
                  style={{ width: 100 }}
                  options={options}
                ></Select>
              </Col>
            </Row>
            <Divider />
          </div>
        );
      })}
      <Button
        className="secondary-btn"
        onClick={() => {
          setActiveTab('3');
        }}
      >
        See the Floor Plan
      </Button>
      <Footer className="main-footer">
        <Row justify="center" className="margin-y">
          {Object.keys(selectedValues).length > 0 ? (
            <Typography.Title level={5}>Once you save, you can't edit the booths.</Typography.Title>
          ) : (
            ''
          )}
        </Row>
        <Row>
          <Button onClick={submit} type="primary" className="primary-btn" block>
            Save
          </Button>
        </Row>
      </Footer>
    </>
  );
}
