import { useSelector } from 'react-redux';
import ChoosingBooth from './ChoosingBooth';
import InvoicePayment from './InvoicePayment';
import ViewBooth from './ViewBooth';

export default function ExhibitTab({ paymentStatus, setActiveTab }) {
  const user = useSelector((state) => state.auth);

  const whichComponent = {
    paid: (
      <ChoosingBooth
        sponsorName={user['Sponsor/Exhibitor Name']}
        sponsorTier={user['Sponsorship Tiers']}
        setActiveTab={setActiveTab}
      />
    ),
    selected: <ViewBooth />,
  };
  return (
    whichComponent[paymentStatus] || <InvoicePayment invoiceLink={user['Invoice URL']} paymentStatus={paymentStatus} />
  );
}
