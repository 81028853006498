import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export default function DownloadTab() {
  return (
    <>
      <a href={require('../assets/files/2023 CBAC Exhibitor Service Manual.pdf')} download>
        <Button type="primary" icon={<DownloadOutlined />} style={{ backgroundColor: 'rgb(166, 15, 27)' }}>
          Download Exhibitor Service Manual
        </Button>
      </a>
      <br />
      <br />
      <a href="https://airtable.com/shrqhC8dpuzGj34pI/tbleLdIjJ4uZAfX8K" target="_blank" rel="noreferrer">
        <Button type="primary" icon={<DownloadOutlined />} style={{ backgroundColor: 'rgb(166, 15, 27)' }}>
          Download Exhibitor Schedule
        </Button>
      </a>
    </>
  );
}
