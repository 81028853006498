import { Alert, Modal } from 'antd';
import Html5QrcodePlugin from './Html5QrcodePlugin';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function QRScanner({ open, handleQrModal }) {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const onNewScanResult = async (decodedText, decodedResult) => {
    const path = decodedText.match(/user-scan\/.*/g)?.[0];
    if (!path) setError('Invalid QR code');
    else {
      handleQrModal();
      navigate(path);
    }
  };

  const config = {
    fps: 10,
    qrbox: 250,
    disableFlip: true,
  };

  return (
    <Modal
      footer={[]}
      destroyOnClose={true}
      title="QR Scanner"
      centered
      open={open}
      onOk={handleQrModal}
      onCancel={handleQrModal}
      width={1000}
    >
      <Html5QrcodePlugin config={config} qrCodeSuccessCallback={onNewScanResult} errorCallback={setError} />
      {error && <Alert message={error} type="error" />}
    </Modal>
  );
}
