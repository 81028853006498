import { Image, Tabs, Typography } from 'antd';
import ExhibitTab from '../components/ExhibitTab';
import DownloadTab from '../components/DownloadTab';
import MainLayout from './layouts/MainLayout';
import floorPlan from '../assets/imgs/Convention Floor Plan.jpg';
import map2 from '../assets/imgs/Argyle Level Floorplan.png';
import map3 from '../assets/imgs/Ballroom Floor Plan.jpg';
import { useEffect, useState } from 'react';
import Tickets from '../components/Tickets';
import { useSelector } from 'react-redux';
import SavedContacts from '../components/SavedContacts';
import { useNavigate } from 'react-router-dom';
import ScheduleTab from '../components/ScheduleTab';
import useWindowDimensions from '../hooks/useWindowDimensions';

const { Title } = Typography;

export default function HomeScreen() {
  const user = useSelector((state) => state.auth);
  const query = new URLSearchParams(window.location.search);
  const Initialtab = query.get('tab');
  const [paymentStatus, setPaymentStatus] = useState('unpaid');
  const [activeTab, setActiveTab] = useState(Initialtab || '6');
  const navigate = useNavigate();
  const windowDimensions = useWindowDimensions();

  const onChange = (key) => {
    navigate(`/?tab=${key}`, { replace: true });
  };

  useEffect(() => {
    console.log(windowDimensions);
  }, [windowDimensions]);

  useEffect(() => {
    setActiveTab(Initialtab || '6');
  }, [Initialtab]);

  useEffect(() => {
    if (!user) return;
    let currentStatus = user['Invoice Status'] || 'unpaid';
    currentStatus = currentStatus.toLowerCase();
    if (currentStatus === 'paid' && user['Booths']) currentStatus = 'selected';

    setPaymentStatus(currentStatus);
  }, [user]);

  const items = [
    {
      key: '6',
      label: 'Schedule',
      children: <ScheduleTab />,
    },
    {
      key: '4',
      label: `Saved Contacts`,
      children: <SavedContacts />,
    },
    {
      key: '3',
      label: `Map`,
      children: (
        <>
          <Title level={2}>Convention Floor Plan</Title>
          <Image src={floorPlan} alt="map" style={{ width: '100%' }} />
          <br />
          <br />
          <Title level={2}>Argyle Level Floor Plan</Title>
          <Image src={map2} alt="map" style={{ width: '100%' }} />
          <br />
          <br />
          <Title level={2}>Ballroom Level Floor Plan</Title>
          <Image src={map3} alt="map" style={{ width: '100%' }} />
        </>
      ),
      disabled: ['unpaid', 'processing'].includes(paymentStatus),
    },
    {
      key: '2',
      label: `Tickets`,
      children: <Tickets />,
      // disabled: paymentStatus !== 'selected',
    },
    {
      key: '1',
      label: `Exhibit`,
      children: <ExhibitTab paymentStatus={paymentStatus} setActiveTab={setActiveTab} />,
    },
    {
      key: '5',
      label: 'Downloads',
      children: <DownloadTab />,
    },
  ];

  return (
    <MainLayout>
      <Tabs tabBarGutter={windowDimensions.width <= 768 && 12} activeKey={activeTab} items={items} onChange={onChange} />
    </MainLayout>
  );
}
