import { useEffect, useState } from 'react';
import http from '../http-common';
import { Button, Row, Space, Table } from 'antd';
import QRScanner from './QRScanner';
import { DownloadOutlined, EditOutlined, QrcodeOutlined, ReloadOutlined } from '@ant-design/icons';
import UpdateSavedContactModal from './UpdateSavedContactModal';
import ConfirmReset from './ConfirmReset';
import { CSVLink } from 'react-csv';

export default function SavedContacts() {
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [qrModal, setQrModal] = useState(false);
  const [contactUpdate, setContactUpdate] = useState(false);
  const [reset, setReset] = useState(false);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'Assignee Name',
      key: 'name',
    },
    {
      title: 'Company',
      dataIndex: 'Company',
      key: 'company',
    },
    {
      title: 'Email',
      dataIndex: 'Assignee Email',
      key: 'email',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button icon=<EditOutlined /> onClick={() => setContactUpdate(record)}>
            Edit
          </Button>
          <Button icon=<ReloadOutlined /> onClick={() => setReset(record)}>
            Reset
          </Button>
        </Space>
      ),
    },
  ];
  useEffect(() => {
    setIsLoading(true);
    http
      .get('api/airtable/saved-contacts')
      .then((response) => {
        setDataSource(response.data.users);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  const handleQrModal = () => {
    setQrModal(!qrModal);
  };

  const RefetchContacts = () => {
    setIsLoading(true);
    http
      .get('api/airtable/saved-contacts')
      .then((response) => {
        setDataSource(response.data.users);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <QRScanner open={qrModal} handleQrModal={handleQrModal} />
      <UpdateSavedContactModal
        isModalOpen={contactUpdate}
        onOk={() => {
          setContactUpdate(false);
          RefetchContacts();
        }}
        onCancel={() => {
          setContactUpdate(false);
        }}
        contact={contactUpdate}
      />
      <ConfirmReset
        isModalOpen={reset}
        onOk={() => {
          setReset(false);
          RefetchContacts();
        }}
        onCancel={() => {
          setReset(false);
        }}
        contact={reset}
      />

      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <Button>
          <CSVLink filename={'saved-contacts-export.csv'} data={dataSource.map(({ id, ...data }) => data)} target="_blank">
            <DownloadOutlined /> Export CSV
          </CSVLink>
        </Button>
        <Button onClick={() => setQrModal(true)}>
          <QrcodeOutlined /> Scan nametag
        </Button>
      </Row>
      <Table
        rowKey="id"
        loading={isLoading}
        dataSource={dataSource}
        columns={columns}
        tableLayout="auto"
        expandable={{
          expandedRowRender: (record) => (
            <p
              style={{
                margin: 0,
              }}
            >
              {record.Notes}
            </p>
          ),
          rowExpandable: (record) => record.Notes,
        }}
        scroll={{
          y: '50vh',
        }}
      />
    </>
  );
}
