import { useCallback, useEffect, useState } from 'react';
import { ExpandAltOutlined, EnvironmentOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Col, Empty, Row, Tag } from 'antd';

import { convertToWeekDay } from '../utils/convertToWeekDay';
import { convertToHours } from '../utils/convertToHours';
import useSchedule from '../hooks/useSchedule';
import '../sass/components/ScheduleTab.scss';
import httpCommon from '../http-common';
import Loading from './Loading';

export default function ScheduleTab() {
  const [status, setStatus] = useState('loading');
  const [data, setData] = useState([]);

  const { getScheduleDates } = useSchedule();

  const getSchedule = useCallback(async () => {
    try {
      setStatus('loading');
      setData([]);

      const response = await httpCommon.get('api/airtable/schedule');

      setData(response.data);
      setStatus('success');
    } catch (e) {
      setStatus('error');
      console.log(e);
    }
  }, []);

  useEffect(() => {
    getSchedule();
  }, [getSchedule]);

  if (status === 'loading') {
    return <Loading inline={false} />;
  }

  if (status === 'success') {
    return (
      <>
        {data.length === 0 ? (
          <Empty />
        ) : (
          <>
            {getScheduleDates(data).map(({ day, start }, index) => (
              <Event key={index} data={data} day={day} date={start} />
            ))}
          </>
        )}
      </>
    );
  }
}

function Event({ data, day, date }) {
  const { getEventsByDay } = useSchedule();
  const navigate = useNavigate();

  return (
    <Row style={{ marginBottom: 18 }}>
      <Col xs={5} md={3} lg={2} style={{ paddingRight: 8 }}>
        <p style={{ fontWeight: 'bold' }}>{day}</p>
        <p>{convertToWeekDay(date)}</p>
      </Col>

      <Col xs={19} md={21} lg={22}>
        {getEventsByDay(data, day).map(({ events, start, end, id, category, location }, index) => {
          return (
            <div
              className="item"
              key={index}
              onClick={() => {
                if (category === 'Session' || category === 'Gala') {
                  navigate(`/schedule/${id}`);
                }
              }}
            >
              <p className="item__title">
                {category === 'Session' || category === 'Gala' ? <ExpandAltOutlined style={{ paddingRight: '10px' }} /> : null}
                {events}{' '}
                {category === 'Session' ? (
                  <Tag color="gold" style={{ fontWeight: 'normal' }}>
                    {category}
                  </Tag>
                ) : category === 'Meal' ? (
                  <Tag color="green" style={{ fontWeight: 'normal' }}>
                    {category}
                  </Tag>
                ) : category === 'Event' ? (
                  <Tag color="blue" style={{ fontWeight: 'normal' }}>
                    {category}
                  </Tag>
                ) : category === 'Registration' ? (
                  <Tag color="default" style={{ fontWeight: 'normal' }}>
                    {category}
                  </Tag>
                ) : category === 'Exhibition' ? (
                  <Tag color="magenta" style={{ fontWeight: 'normal' }}>
                    {category}
                  </Tag>
                ) : category === 'Gala' ? (
                  <Tag color="red" style={{ fontWeight: 'normal' }}>
                    {category}
                  </Tag>
                ) : (
                  <Tag color="default" style={{ fontWeight: 'normal' }}>
                    {category}
                  </Tag>
                )}
              </p>

              <p className="item__subtitle">
                <ClockCircleOutlined style={{ fontSize: 12 }} /> {convertToHours(start)} - {convertToHours(end)}
              </p>

              <p className="item__subtitle__location">
                <EnvironmentOutlined /> {location}
              </p>

              <hr className="item__divider" />
            </div>
          );
        })}
      </Col>
    </Row>
  );
}
