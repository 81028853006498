import axios from 'axios';
import { PORT } from './consts/PORT';

const token = localStorage.getItem('userToken');

export default axios.create({
  baseURL: PORT,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
