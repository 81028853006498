import { useState } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import http from '../http-common';
// import './index.scss';

import React from 'react';

export default function AssignTicketModal(props) {
  const [submissionLoading, setSubmissionLoading] = useState(false);

  const onFinish = (values) => {
    setSubmissionLoading(true);
    http
      .post('api/airtable/assign-ticket', {
        ticketID: props?.ticket?.id,
        assigneeName: values.name,
        email: values.email,
      })
      .then(() => {
        setSubmissionLoading(false);
        props.onOk();
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal title="Assign Ticket" open={props.isModalOpen} onOk={props.onOk} onCancel={props.onCancel} footer={[]}>
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input a name',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: 'email',
              required: true,
              message: 'Please input an email',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
          <Button loading={submissionLoading} type="primary" className="primary-btn" htmlType="submit">
            Confirm Assign
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
