import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Row, Typography } from 'antd';
import http from '../http-common';
import { useState } from 'react';

export default function ProfileContact({ contactData, deleteContact }) {
  const contact = contactData.fields;
  const [loading, setLoading] = useState(false);
  return (
    <Row justify="space-between" align="middle" style={{ marginTop: 16 }}>
      <Row>
        <Col>
          <Avatar size={40} icon={<UserOutlined />} src={contact?.['Pic']?.[0]?.url} />
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <Typography.Paragraph strong style={{ marginBottom: 0 }}>
            {contact?.['First Name']} {contact?.['Last Name']}
          </Typography.Paragraph>
          <Typography.Text style={{ fontSize: 12 }} type="secondary">
            {contact?.['Title']}
          </Typography.Text>
        </Col>
      </Row>

      <Col>
        <Button
          style={{ fontWeight: 600 }}
          danger
          loading={loading}
          onClick={() => {
            setLoading(true);
            deleteContact(contactData.id);
          }}
        >
          Delete
        </Button>
      </Col>
    </Row>
  );
}
