/* eslint-disable no-throw-literal */
import { Html5Qrcode } from 'html5-qrcode';
import { useEffect } from 'react';

const qrcodeRegionId = 'html5qr-code-full-region';

const Html5QrcodePlugin = (props) => {
  useEffect(() => {
    // when component mounts
    const config = props.config || {};
    // Suceess callback is required.
    if (!props.qrCodeSuccessCallback) {
      throw 'qrCodeSuccessCallback is required callback.';
    }
    const html5Qrcode = new Html5Qrcode(qrcodeRegionId);
    let started = false;
    html5Qrcode
      .start({ facingMode: 'environment' }, config, props.qrCodeSuccessCallback)
      .then(() => (started = true))
      .catch((err) => {
        props.errorCallback(err);
      });

    // cleanup function when component will unmount
    return () => {
      if (started) {
        started = false;
        html5Qrcode.stop().catch((error) => {
          console.error('Failed to stop html5Qrcode. ', error);
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id={qrcodeRegionId} />;
};

export default Html5QrcodePlugin;
