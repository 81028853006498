import { Spin } from 'antd';
import { LoadingIcon } from './LoadingIcon';

export default function Loading({ inline }) {
  return (
    <div className={inline ? 'loading' : 'loading main'}>
      <Spin size="large" indicator={LoadingIcon} />
    </div>
  );
}
