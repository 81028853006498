import React, { Component } from 'react';
import { Layout, Form, Input, Alert, Space, Divider, Checkbox, Typography, Button } from 'antd';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

import * as actions from '../actions';
import { Header } from 'antd/es/layout/layout';

const logo = 'https://canadianbrewingawards.com/wp-content/uploads/2023/03/CBA-LOGO_FINAL_2023-Halifax.png';

class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = { loginError: '', loading: false };
  }

  onSubmit = (values) => {
    this.setState({ loginError: '', loading: true });
    const query = new URLSearchParams(window.location.search);
    const nextPath = query.get('next');
    axios
      .post('/auth/login', {
        email: values.email,
        password: values.password,
      })
      .then((response) => {
        // Enter login authentication flow

        if (response.status === 200) {
          localStorage.setItem('userToken', response.data?.token);
          window.location.replace(nextPath || '/');
        }
      })
      .catch((error) => {
        console.log('login error: ', error);
        this.setState({
          loginError: 'There was an error logging in. Please enter the correct email and password.',
          loading: false,
        });
      });
  };

  onSubmitFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  renderContent = () => {
    switch (this.props.auth) {
      case null:
        // Wait while user is being determined
        return;
      case '':
        // If no user, show login screen
        return (
          <Layout className="layout">
            <Header className="layout__header">
              <Typography.Title level={3} className="layout__header_title">
                Welcome to the Sponsors Portal!
              </Typography.Title>
              <Typography.Paragraph className="layout__header_sub">
                If you have an account, please log in below. Otherwise, please contact
                confernce@canadianbrewingawards.com
              </Typography.Paragraph>
            </Header>
            <div className="login">
              <Space className="login__header" direction="vertical" size={1}>
                <div className="login__header_logo">
                  <img alt="logo" src={logo} />
                </div>
              </Space>
              <Form
                name="login"
                className="login__form"
                initialValues={{ remember: true }}
                onFinish={this.onSubmit}
                onFinishFailed={this.onSubmitFailed}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email',
                    },
                    {
                      type: 'email',
                      message: 'The input is not a valid email.',
                    },
                  ]}
                >
                  <Input placeholder="Email" className="login__form_input" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
                <Checkbox>Remember me</Checkbox>

                <Form.Item>
                  <Button loading={this.state.loading} htmlType="submit" className="login__form_button" block>
                    Sign In
                  </Button>
                  {this.state.loginError ? (
                    <>
                      <br />
                      <br />
                      <Alert message={this.state.loginError} type="error" />
                    </>
                  ) : null}
                </Form.Item>
              </Form>
              <Divider />
              <Button type="link" href="#" className="recover-link">
                Recover Password
              </Button>
            </div>
          </Layout>
        );
      default:
        // If user exists, redirect to home (Clients Screen)
        return <Navigate to="/" />;
    }
  };

  render() {
    return <>{this.renderContent()}</>;
  }
}

function mapStateToProps({ auth }) {
  return {
    auth,
  };
}

export default connect(mapStateToProps, actions)(LoginScreen);
