import { FETCH_USER, LOGOUT_USER, LOGIN_USER, UPDATE_USER } from '../actions/types';

export default function authReducer(state = null, action) {
  switch (action.type) {
    case FETCH_USER:
      return action.payload;
    case LOGIN_USER:
      return action.payload;
    case LOGOUT_USER:
      return action.payload;
    case UPDATE_USER:
      return action.payload;
    default:
      return state;
  }
}
