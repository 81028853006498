import { UserOutlined } from '@ant-design/icons';
import { Avatar, Col, Row } from 'antd';

export default function Speakers({ data }) {
  return (
    <div className="speakers">
      <div className="speakers__title">
        <h3>Speakers</h3>
      </div>

      <div>
        {data.length === 0 ? (
          <p>No speakers.</p>
        ) : (
          <Row gutter={[10, 20]}>
            {data.map((speaker, index) => (
              <Col span={8} style={{ textAlign: 'center' }} key={index}>
                <Avatar shape="circle" size={64} icon=<UserOutlined /> src={speaker.pic?.[0]?.url} />
                <p style={{ fontWeight: 'bold', textAlign: 'center' }}>{speaker.fullName}</p>
                <p style={{ color: '#555', textAlign: 'center' }}>{speaker.title ? speaker.title : ' '}</p>
              </Col>
            ))}
          </Row>
        )}
      </div>
    </div>
  );
}
