import { CalendarOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Row, Col, Typography } from 'antd';
import { Link } from 'react-router-dom';

export default function TopNav() {
  return (
    <Row className="top-nav" align="middle" justify="space-between" gutter={[12, 12]}>
      <Col className="nav-item">
        <Link to="/">
          <Typography.Title level={2}>2023 CBA Conference</Typography.Title>
        </Link>
      </Col>
      <Col className="nav-item">
        <CalendarOutlined />
        <Typography.Text>Thu June 1, 2023 - Sat June 3, 2023</Typography.Text>
      </Col>
      <Col className="nav-item">
        <EnvironmentOutlined />
        <Typography.Text>
          Halifax Convention Centre
          <br /> 1650 Argyle St, Halifax, NS
        </Typography.Text>
      </Col>
    </Row>
  );
}
