import { Button, Divider, Typography } from 'antd';

export default function InvoicePayment({ invoiceLink, paymentStatus }) {
  const status = paymentStatus || 'unpaid';
  const text = {
    processing: 'Your payment is being processed right now, please refresh the page to update the status.',
    unpaid: 'You have an outstanding invoice. If you just paid the invoice, please refresh this page.',
    failed: 'There was an issue in the payment. please contact confernce@canadianbrewingawards.com for more info.',
  }[status];

  return (
    <>
      <Typography.Paragraph strong className="no-margin">
        {text}
      </Typography.Paragraph>
      <Typography.Paragraph type="secondary">
        As soon as your invoice is paid, you will be able to select your booth(s) and assign tickets to your staff.
      </Typography.Paragraph>
      <Divider />
      <Button target="_blank" disabled={paymentStatus !== 'unpaid'} className="primary-btn" href={invoiceLink}>
        Pay Invoice
      </Button>
    </>
  );
}
