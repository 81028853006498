import { useState } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import http from '../http-common';

export default function UpdateSavedContactModal(props) {
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const { contact } = props;
  const onFinish = (values) => {
    setSubmissionLoading(true);
    http
      .put(`api/airtable/saved-contacts/${contact.id}`, values)
      .then(() => {
        setSubmissionLoading(false);
        props.onOk();
      })
      .catch((error) => {
        console.log('error', error);
        setSubmissionLoading(false);
        props.onCancel();
      });
  };

  return (
    <Modal title="Update Contact" open={props.isModalOpen} onOk={props.onOk} onCancel={props.onCancel} footer={[]} destroyOnClose={true}>
      <Form name="basic" initialValues={contact} onFinish={onFinish}>
        <Form.Item
          label="Name"
          name="Assignee Name"
          rules={[
            {
              type: 'text',
              required: true,
              message: 'Please input a name',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="Assignee Email"
          rules={[
            {
              type: 'email',
              required: true,
              message: 'Please input an email',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Company"
          name="Company"
          rules={[
            {
              required: true,
              message: 'Please input an company name',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Notes" name="Notes">
          <Input.TextArea />
        </Form.Item>

        <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
          <Button loading={submissionLoading} type="primary" danger size="large" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
