export function convertToHours(time) {
  const date = new Date(`${time}`);
  const adtDate = date.toLocaleString('en-US', { timeZone: 'America/Halifax' });
  const adt = new Date(adtDate);
  const hours = adt.getHours();
  const minutes = adt.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
}
