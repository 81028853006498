import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Layout, Menu } from 'antd';
import Loading from '../../components/Loading';
import TopNav from '../../components/TopNav';
import Sider from 'antd/es/layout/Sider';
import { LogoutOutlined, MenuOutlined, ShoppingCartOutlined, UserOutlined } from '@ant-design/icons';
import { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useClickOutside from '../../hooks/useClickOutside';
const { Content, Header } = Layout;

// Used as the main layout style for most app pages. Includes header, general content area, and footer.
export default function MainLayout(props) {
  const auth = useSelector((state) => state.auth);
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const siderRef = useRef(null);
  useClickOutside(siderRef, () => setCollapsed(true));

  const logout = () => {
    localStorage.removeItem('userToken');
    window.location.href = '/';
  };

  const renderContent = () => {
    switch (auth) {
      // Show spinner while user is being determined
      case null:
        return <Loading />;

      // If empty user, redirect to login
      case '': {
        window.location.href = `/login?next=${window.location.pathname}`;
        return null;
      }

      // If user exists, display layout with current page
      default:
        return (
          <Layout style={{ minHeight: '100vh' }} hasSider>
            <Sider
              style={{
                height: '100vh',
                position: 'fixed',
                zIndex: '999',
                right: 0,
                top: 0,
                bottom: 0,
              }}
              collapsible
              collapsed={collapsed}
              width={200}
              collapsedWidth={0}
              trigger={null}
              ref={siderRef}
            >
              <Button
                className="sider-trigger"
                icon=<MenuOutlined />
                onClick={() => {
                  setCollapsed(!collapsed);
                }}
              />
              <Menu
                theme="dark"
                mode="inline"
                items={[
                  {
                    key: '1',
                    icon: <LogoutOutlined />,
                    onClick: logout,
                    label: 'Log Out',
                  },
                  {
                    key: '2',
                    icon: <UserOutlined />,
                    onClick: () => navigate('/edit-profile'),
                    label: 'Edit Profile',
                  },
                  {
                    key: '3',
                    icon: <ShoppingCartOutlined />,
                    onClick: () => {
                      navigate('/?tab=2');
                      setCollapsed(true);
                    },
                    label: 'My Tickets',
                  },
                ]}
              ></Menu>
            </Sider>
            <Layout className="site-layout">
              {!props?.withoutHeader && (
                <Header className="main-header">
                  <TopNav />
                </Header>
              )}
              <Content className="site-layout-content" style={{ padding: '24px', marginBottom: 100 }}>
                <div className="site-layout-content-inner">{props.children}</div>
              </Content>
            </Layout>
          </Layout>
        );
    }
  };

  return <>{renderContent()}</>;
}

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
