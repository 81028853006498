import { Button, Col, Result, Row, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';
import http from '../http-common';
import logo from '../assets/imgs/CBAC-Red-Logo.png';
import MainLayout from './layouts/MainLayout';
import { Link, useParams } from 'react-router-dom';
import Loading from '../components/Loading';

export function ScanNametagScreen() {
  const { ticketId } = useParams();
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  useEffect(() => {
    http
      .get(`api/airtable/user-scan/${ticketId}`)
      .then((res) => {
        api.success({
          message: res.data.status,
          placement: 'topRight',
        });
        setUser(res.data);
      })
      .catch((error) => {
        setError(error?.response?.data || 'Failed to scan!');
      });
  }, [ticketId]);

  if (error) {
    return (
      <MainLayout>
        <Result
          status="error"
          title={error}
          extra={
            <Link to="/?tab=4">
              <Button className="primary-btn">Back Home</Button>
            </Link>
          }
        />
      </MainLayout>
    );
  }
  if (!user)
    return (
      <MainLayout>
        {contextHolder}
        <Loading />
      </MainLayout>
    );
  return (
    <MainLayout>
      {contextHolder}
      <Row gutter={[50, 25]}>
        <Col style={{ display: 'flex', alignItems: 'center', justifyItems: 'flex-start' }}>
          <img
            src={logo}
            alt="logo"
            style={{
              width: '150px',
            }}
          />
        </Col>
        <Col>
          <Typography.Title>{user?.['Assignee Name']}</Typography.Title>
          <Typography.Title level={5}>Company: {user?.['Company']}</Typography.Title>
          <Typography.Title level={4}>{user?.['Assignee Email']}</Typography.Title>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 30 }}>
        <Link to="/?tab=4">
          <Button className="primary-btn">Back Home</Button>
        </Link>
      </Row>
    </MainLayout>
  );
}
