import { useEffect, useState } from 'react';
import http from '../http-common';
import Loading from '../components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Divider, Form, Image, Input, Row, Typography, notification } from 'antd';
import { ArrowLeftOutlined, HomeOutlined, TrophyFilled } from '@ant-design/icons';
import starIcon from '../assets/imgs/starIcon.svg';
import { Link, useNavigate } from 'react-router-dom';
import { updateUser } from '../actions';
import CreateContactModal from '../components/CreateContactModal';
import ProfileContact from '../components/ProfileContact';

export function EditProfileScreen() {
  const auth = useSelector((state) => state.auth);
  const [contacts, setContacts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [loadingContacts, setLoadingContacts] = useState(false);

  let navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const errorNotif = (error) => {
    api.error({
      message: error?.response?.data?.error?.message || 'Something went wrong!',
      placement: 'topRight',
    });
  };

  const onFinish = (values) => {
    setLoading(true);
    if (values?.Logo) values['Logo'] = [{ url: values['Logo'] }];
    else delete values.Logo;

    http
      .put('auth/user', values)
      .then((response) => {
        api.success({
          message: 'Details Updated',
          placement: 'topRight',
        });
        setLoading(false);
        dispatch(updateUser(response.data));
      })
      .catch((error) => {
        setLoading(false);
        errorNotif(error);
        console.log(error);
      });
  };

  const showModal = () => {
    setModalOpen(true);
  };

  const handleOk = () => {
    fetchContacts();
    setModalOpen(false);
    api.success({
      message: 'Contact Created',
      placement: 'topRight',
    });
  };

  const handleCancel = () => {
    setModalOpen(false);
  };
  const fetchContacts = () => {
    setLoadingContacts(true);
    http
      .get('api/airtable/contacts')
      .then((response) => {
        setContacts(response.data);
        setLoadingContacts(false);
      })
      .catch((error) => {
        errorNotif(error);
        setContacts([]);
        setLoadingContacts(false);
      });
  };

  const deleteContact = (contactId) => {
    http
      .delete(`api/airtable/contacts/${contactId}`)
      .then((response) => {
        fetchContacts();
        api.success({
          message: 'Contact Deleted',
          placement: 'topRight',
        });
      })
      .catch((error) => {
        errorNotif(error);
        console.log(error);
      });
  };

  useEffect(() => {
    fetchContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (auth === null || contacts === null) {
    return <Loading />;
  }
  if (auth === '') {
    window.location.href = '/login';
    return;
  }
  return (
    <div style={{ margin: 24 }} className="site-layout edit-profile">
      {contextHolder}
      <div className="site-layout-content">
        <div className="site-layout-content-inner">
          <Row style={{ margin: 0 }}>
            <Typography.Title level={4} onClick={() => navigate(-1)}>
              <ArrowLeftOutlined style={{ marginRight: 16 }} />
            </Typography.Title>
            <Typography.Title level={4}>Edit Profile</Typography.Title>
          </Row>
          <Row justify="space-between" align="middle">
            <Col>
              <Row style={{ margin: '17px 0' }}>
                <Typography.Text type="secondary" style={{ marginRight: 12 }}>
                  <HomeOutlined />
                </Typography.Text>
                <Typography.Text strong={true}>Booths: {auth?.['Booths (Plaintext)']}</Typography.Text>
              </Row>
              <Row style={{ margin: 0 }} align="middle">
                <Typography.Text type="secondary">
                  <img src={starIcon} alt="stars" />
                </Typography.Text>
                {(auth?.['Sponsorship Tiers'] || []).map((value, index) => {
                  return (
                    <Typography.Text key={index} className="tier tier-platinum">
                      <TrophyFilled /> {value}
                    </Typography.Text>
                  );
                })}
              </Row>
            </Col>
            <Col span={5}>
              <Image src={auth?.['Logo']?.[0]?.url} />
            </Col>
          </Row>

          <Form
            form={form}
            onFinish={onFinish}
            style={{ marginTop: 17, marginBottom: 40 }}
            initialValues={{ ...auth, Logo: null }}
            layout="vertical"
          >
            <Form.Item label="Company Name" name="Sponsor/Exhibitor Name" style={{ marginBottom: 14 }}>
              <Input placeholder="Company name" />
            </Form.Item>

            <Form.Item label="Video Embed (iframe)" name="Video Link" style={{ marginBottom: 14 }}>
              <Input placeholder="<iframe></iframe>" />
            </Form.Item>

            <Form.Item label="Bio" name="Company Bio" style={{ marginBottom: 14 }}>
              <Input.TextArea placeholder="Welcome to the Canadian Brewing Awards," />
            </Form.Item>

            <Form.Item label="Logo Link" name="Logo" style={{ marginBottom: 14 }}>
              <Input placeholder="https://" />
            </Form.Item>
          </Form>
          <Typography.Title level={5}>Contacts</Typography.Title>
          {loadingContacts ? (
            <Loading inline={true} />
          ) : (
            contacts.map((contactData) => (
              <ProfileContact key={contactData.id} contactData={contactData} deleteContact={deleteContact} />
            ))
          )}
          <Button block type="dashed" danger style={{ marginTop: 24, fontWeight: 600 }} onClick={showModal}>
            + Add Contacts
          </Button>
          <Divider />
          <Row justify="space-between">
            <Col span={11}>
              <Button size="large" loading={loading} type="primary" danger block onClick={() => form.submit()}>
                Save Changes
              </Button>
            </Col>
            <Col span={11}>
              <Link to="/">
                <Button size="large" block>
                  Cancel
                </Button>
              </Link>
            </Col>
          </Row>
          <CreateContactModal isModalOpen={modalOpen} onOk={handleOk} onCancel={handleCancel} />
        </div>
      </div>
    </div>
  );
}
