import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './actions';

import LoginScreen from './screens/LoginScreen';
import HomeScreen from './screens/HomeScreen';
import { ViewTicketScreen } from './screens/ViewTicketScreen';
import { ScanNametagScreen } from './screens/ScanNametagScreen';
import { EditProfileScreen } from './screens/EditProfileScreen';
import EventScreen from './screens/EventScreen';

class App extends Component {
  componentDidMount() {
    this.props.fetchUser(); // fetches the current user
  }

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomeScreen />} />
          <Route exact path="/schedule/:scheduleId" element={<EventScreen />} />
          <Route exact path="/login" element={<LoginScreen />} />
          <Route path="/ticket-qr/:ticketId" element={<ViewTicketScreen />} />
          <Route path="/user-scan/:ticketId" element={<ScanNametagScreen />} />
          <Route path="/edit-profile" element={<EditProfileScreen />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default connect(null, actions)(App);
