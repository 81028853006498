import { FileImageOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';
import Loading from '../Loading';
import http from '../../http-common';

export default function ViewBooth() {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    http
      .get('api/airtable/booths?me=true')
      .then((response) => {
        setOptions(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);
  if (isLoading) return <Loading />;

  return Object.keys(options).map((booth) => {
    return (
      <div key={booth}>
        <Row align="middle" className="font-roboto">
          <Col span={5}>
            <FileImageOutlined style={{ fontSize: '40px', color: '#00000073' }} />
          </Col>
          <Col>
            <Typography.Title level={5}>{options[booth].label}</Typography.Title>
            <Typography.Paragraph type="secondary" className="no-margin">
              10x10 Show Booth
            </Typography.Paragraph>
          </Col>
          <Divider />
        </Row>
      </div>
    );
  });
}
