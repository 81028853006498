import { Button, Col, Divider, Modal, Row, Select, Typography, Input } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import http from '../http-common';

export default function BuyTicketModal(props) {
  const user = useSelector((state) => state.auth);
  const [quantities, setQuantities] = useState({});
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [referralCode, setReferralCode] = useState('');

  const optionsCount = 10;
  let options = [];
  for (let i = 1; i <= optionsCount; i++) {
    options.push({
      value: i,
      label: i,
    });
  }

  const ticketTypes = [
    // {
    //   key: 'quantityEarly',
    //   label: 'Early Bird Registration',
    //   price: '$350.00',
    //   description: 'Full access to the entire 3-day event.',
    // },
    {
      key: 'conferenceQuantity',
      label: 'CBAC Conference Ticket',
      price: '$400.00',
      description: 'Full access to the entire 3-day event.',
    },
    {
      key: 'quantityGala',
      label: 'Gala Only Ticket',
      price: '$200.00',
      description: 'Attend the gala on Saturday night.',
    },
    {
      key: 'quantityExhibition',
      label: 'Exhibition Only Ticket',
      price: '$200.00',
      description:
        'This ticket grants you access to the exhibition floor, information sessions, and evening parties. It does not include the Gala.',
    },
  ];

  const handleSelect = (value, key) => {
    let newQuantities = { ...quantities };
    newQuantities[key] = value;
    setQuantities(newQuantities);
  };

  const submit = () => {
    setSubmissionLoading(true);
    //'http://localhost:7000/stripe/create-checkout-session?returnUrl=true'
    //'https://brewery-entries.herokuapp.com/stripe/create-checkout-session?returnUrl=true'
    http
      .post(
        `${
          process.env.REACT_APP_TICKETS_BASE_URL || 'https://brewers.canadianbrewingawards.com'
        }/stripe/create-checkout-session?returnUrl=true`,
        {
          ...quantities,
          referralCode,
          company: user['Sponsor/Exhibitor Name'],
          attendeeEmail: user['Company Email'],
          // successUrl: 'http://localhost:3000',
          // cancelUrl: 'http://localhost:3000',
          successUrl: process.env.REACT_APP_SUCCESS_URL || 'https://sponsors.canadianbrewingawards.com',
          cancelUrl: process.env.REACT_APP_CANCEL_URL || 'https://sponsors.canadianbrewingawards.com',
          attendeeType: 'Exhibitor/Sponsor',
          assigneeData: {},
        },
      )
      .then((response) => {
        window.location.href = response.data;
      })
      .catch((error) => {
        setSubmissionLoading(false);
        console.log(error);
      });
  };

  const onReferralCodeChange = (e) => {
    setReferralCode(e.target.value.toUpperCase());
  };
  return (
    <Modal title="Buy Tickets" open={props.isModalOpen} onOk={props.onOk} onCancel={props.onCancel} footer={[]}>
      {ticketTypes.map((ticket, index) => {
        return (
          <div key={index}>
            <Row align="middle" gutter={16} justify="space-between">
              <Col span={16}>
                <Typography.Paragraph strong className="no-margin">
                  {ticket.label}
                </Typography.Paragraph>
                <Typography.Paragraph strong className="no-margin">
                  {ticket.price}
                </Typography.Paragraph>
                <Typography.Text type="secondary">{ticket.description}</Typography.Text>
              </Col>
              <Col flex="auto">
                <Select
                  onChange={(value) => {
                    handleSelect(value, ticket.key);
                  }}
                  placeholder="Select"
                  style={{ width: '100%' }}
                  options={options}
                ></Select>
              </Col>
            </Row>
            <Divider />
          </div>
        );
      })}

      <br />
      <Button
        disabled={Object.keys(quantities).length <= 0}
        loading={submissionLoading}
        type="primary"
        className="primary-btn"
        block
        onClick={submit}
      >
        Checkout
      </Button>
    </Modal>
  );
}
