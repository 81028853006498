import { Button, Col, Divider, Empty, Row, Typography, notification } from 'antd';
import { Footer } from 'antd/es/layout/layout';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import http from '../http-common';
import AssignTicketModal from './AssignTicketModal';
import BuyTicketModal from './BuyTicketModal';
import Loading from './Loading';

export default function Tickets() {
  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ModalOpen, setModalOpen] = useState(0);
  const [modalTicket, setModalTicket] = useState({});
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    setIsLoading(true);
    http
      .get('api/airtable/tickets')
      .then((response) => {
        setTickets(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  const showModal = (ticket, whichModal) => {
    setModalTicket(ticket);
    setModalOpen(0);
    setModalOpen(whichModal);
  };

  const handleOk = () => {
    setModalOpen(0);
    setIsLoading(true);
    http
      .get('api/airtable/tickets')
      .then((response) => {
        setTickets(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleCancel = () => {
    setModalOpen(0);
  };

  const handleEmailResend = (ticketId, assigneeEmail) => {
    setIsLoading(true);
    http
      .post(`api/airtable/resend-email/${ticketId}`)
      .then((response) => {
        setIsLoading(false);
        api.success({ message: `Email sent to the ${assigneeEmail}!`, placement: 'topRight' });
      })
      .catch((error) => {
        setIsLoading(false);
        api.error({
          message: error?.response?.data || 'Something went wrong when attempting to send the email',
          placement: 'topRight',
        });
      });
  };

  if (isLoading)
    return (
      <>
        {contextHolder}
        <Loading />
      </>
    );

  const copyTicket = (id) => {
    navigator.clipboard.writeText(id);
    api.success({
      message: 'Ticket ID copied to clipboard!',
      placement: 'topRight',
    });
  };
  const allTickets = tickets.map((ticket) => {
    const assigneeEmail = ticket?.assigneeEmail || 'No Assignee';
    const isAssigned = assigneeEmail !== 'No Assignee';

    return (
      <div key={ticket.id}>
        <Row justify="space-between">
          <Col>
            <Typography.Title level={5}>{ticket?.ticketType} Ticket</Typography.Title>
            <Typography.Text
              onClick={() => copyTicket(`#${ticket.purchaseId}`)}
              ellipsis={true}
              style={{ maxWidth: '30vw' }}
              type="secondary"
            >
              #{ticket.purchaseId}
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Paragraph type="secondary" className="no-margin no-padding">
              {ticket?.assigneeName}
            </Typography.Paragraph>
            <Typography.Text type="secondary" className="no-margin">
              {assigneeEmail}
            </Typography.Text>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }} gutter={[16, 10]}>
          <Col>
            <Button className="primary-btn" onClick={() => showModal(ticket, 1)}>
              Assign
            </Button>
          </Col>
          <Col>
            <Link to={`/ticket-qr/${ticket?.id}`}>
              <Button className="secondary-btn">View</Button>
            </Link>
          </Col>
          <Col>
            <Button disabled={!isAssigned} className="secondary-btn" onClick={() => handleEmailResend(ticket.id, assigneeEmail)}>
              Resend Email
            </Button>
          </Col>
        </Row>
        <Divider />
      </div>
    );
  });

  return (
    <>
      {contextHolder}

      <AssignTicketModal isModalOpen={ModalOpen === 1} onOk={handleOk} onCancel={handleCancel} ticket={modalTicket} />
      <BuyTicketModal isModalOpen={ModalOpen === 2} onOk={handleOk} onCancel={handleCancel} ticket={modalTicket} />
      {allTickets.length > 0 ? allTickets : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No tickets purchased yet" />}
      <Footer className="main-footer">
        <Button onClick={() => showModal(null, 2)} type="primary" className="primary-btn" block>
          Buy Tickets
        </Button>
      </Footer>
    </>
  );
}
