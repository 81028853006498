import { useState } from 'react';
import { Modal } from 'antd';
import http from '../http-common';
// import './index.scss';

import React from 'react';

export default function ConfirmReset(props) {
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const { contact } = props;
  const onFinish = () => {
    setSubmissionLoading(true);
    http
      .put(`api/airtable/saved-contacts/${contact.id}`, { 'Assignee Name': '', 'Assignee Email': '', Company: '' })
      .then(() => {
        setSubmissionLoading(false);
        props.onOk();
      })
      .catch((error) => {
        setSubmissionLoading(false);
        console.log('error', error);
        props.onCancel();
      });
  };

  return (
    <Modal
      title="Reset Contact"
      open={props.isModalOpen}
      onOk={onFinish}
      onCancel={props.onCancel}
      okButtonProps={{ loading: submissionLoading, danger: true }}
    >
      <p>Are you sure you want to reset the contact information? This action is irreversable.</p>
    </Modal>
  );
}
