import { useState } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import http from '../http-common';
// import './index.scss';

import React from 'react';
import httpCommon from '../http-common';

export default function CreateContactModal(props) {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    if (values?.Pic) values['Pic'] = [{ url: values['Pic'] }];
    http
      .post('api/airtable/contacts', values)
      .then((response) => {
        setLoading(false);
        props.onOk();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <Modal
      title="Add Contact"
      open={props.isModalOpen}
      onOk={props.onOk}
      onCancel={props.onCancel}
      footer={[]}
      destroyOnClose={true}
      closable={!loading}
      maskClosable={!loading}
    >
      <Form name="basic" onFinish={onFinish} autoComplete="off">
        <Form.Item
          label="First Name"
          name="First Name"
          rules={[
            {
              required: true,
              message: 'Please input a name',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Last Name" name="Last Name">
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="Email"
          rules={[
            {
              type: 'email',
              required: true,
              message: 'Please input an email',
            },
          ]}
        >
          <Input placeholder="example@gmail.com" />
        </Form.Item>
        <Form.Item
          label="Job Title"
          name="Title"
          rules={[
            {
              required: true,
              message: 'Please input a job title',
            },
          ]}
        >
          <Input placeholder="CEO" />
        </Form.Item>

        <Form.Item label="Phone Number" name="Phone Number">
          <Input />
        </Form.Item>
        <Form.Item label="Profile Picture URL" name="Pic">
          <Input placeholder="https://" />
        </Form.Item>

        <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
          <Button loading={loading} type="primary" danger size="large" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
