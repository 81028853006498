import _ from 'underscore';

import { upperCaseFirstWord } from '../utils/upperCaseFirstWord';
import { convertToHours } from '../utils/convertToHours';

export default function useSchedule() {
  const getEventById = (data, id) => {
    return data.find((item) => item.id === id);
  };

  const showDateEvent = (data) => {
    const day = new Date(data.start).toLocaleDateString([], { month: 'short', day: 'numeric' });
    const weekday = new Date(data.start).toLocaleDateString([], { weekday: 'short' });
    const startHour = convertToHours(data.start);
    const endHour = convertToHours(data.end);

    return `${upperCaseFirstWord(weekday)} ${day} ${startHour} - ${endHour}`;
  };

  const getScheduleDates = (data) => {
    return _.uniq(data, (data) => data.day);
  };

  const getEventsByDay = (data, day) => {
    return data.filter((item) => item.day === day);
  };

  return { getScheduleDates, getEventsByDay, getEventById, showDateEvent };
}
