import { Button, QRCode, Row, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import http from '../http-common';
import logo from '../assets/imgs/CBAC-Red-Logo.png';
import MainLayout from './layouts/MainLayout';
import { Link, useParams } from 'react-router-dom';
import Loading from '../components/Loading';

export function ViewTicketScreen() {
  const { ticketId } = useParams();
  const [scanUrl, setscanUrl] = useState('');
  useEffect(() => {
    http.get(`api/airtable/scan-url/${ticketId}`).then((res) => {
      setscanUrl(res.data);
    });
  }, [ticketId]);
  if (scanUrl === '')
    return (
      <MainLayout>
        <Loading />
      </MainLayout>
    );
  return (
    <MainLayout>
      <Space
        direction="vertical"
        size="middle"
        align="center"
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: '80%',
        }}
      >
        <img
          src={logo}
          alt="logo"
          style={{
            width: '150px',
          }}
        />
        <Typography.Paragraph style={{ textAlign: 'center', fontSize: 10 }}>
          Thursday, June 1 - Saturday, June 3 2023
          <br />
          Halifax Convention Centre
          <br />
          Halifax, Nova Scotia
        </Typography.Paragraph>
        <QRCode value={scanUrl} />
        <Row justify="center">
          <Link to="/?tab=2">
            <Button className="primary-btn">Back Home</Button>
          </Link>
        </Row>
      </Space>
    </MainLayout>
  );
}
