import { CalendarOutlined, EnvironmentOutlined, LeftOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Result, Row, Typography } from 'antd';

import Speakers from '../components/ScheduleTab/Speakers';
import useSchedule from '../hooks/useSchedule';
import MainLayout from './layouts/MainLayout';
import Loading from '../components/Loading';
import httpCommon from '../http-common';

export default function EventScreen() {
  const [status, setStatus] = useState('loading');
  const [data, setData] = useState(null);
  const { showDateEvent } = useSchedule();

  const { scheduleId } = useParams();
  const navigate = useNavigate();

  const getScheduleById = useCallback(async (scheduleId) => {
    try {
      setStatus('loading');
      setData([]);

      const response = await httpCommon.get(`api/airtable/schedule/${scheduleId}`);

      setData(response.data);
      setStatus('success');
    } catch (e) {
      setStatus('error');
      console.log(e);
    }
  }, []);

  useEffect(() => {
    getScheduleById(scheduleId);
  }, [getScheduleById, scheduleId]);

  if (status === 'loading') {
    return <Loading inline={false} />;
  }

  if (status === 'error') {
    return (
      <Result
        title="Error request"
        extra={
          <Button type="primary" onClick={() => getScheduleById(scheduleId)}>
            Try again
          </Button>
        }
      />
    );
  }

  if (status === 'success') {
    return (
      <MainLayout withoutHeader={true}>
        <Row className="top-nav" align="middle" justify="space-between" gutter={[12, 12]}>
          <Col className="nav-item">
            <Typography.Title level={2}>
              <span className="cursor-pointer">
                <LeftOutlined onClick={() => navigate('/?tab=6')} />
              </span>
              {data.events}
            </Typography.Title>
          </Col>

          <Col className="nav-item">
            <CalendarOutlined />
            <Typography.Text>{showDateEvent(data)}</Typography.Text>
          </Col>

          <Col className="nav-item">
            <EnvironmentOutlined />
            <Typography.Text>{data?.location}</Typography.Text>
          </Col>
        </Row>

        <Speakers data={data.speakers} />

        <div className="description">
          <div className="description__title">
            <h3>Description</h3>
          </div>

          <div className="description__content">
            <p>{data.description ? data.description : 'No description'}</p>
          </div>
        </div>
      </MainLayout>
    );
  }
}
