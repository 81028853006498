// import axios from 'axios';
import { FETCH_USER, UPDATE_USER, LOGIN_USER } from './types';
import modifiedAxios from '../http-common';
// Fetch the current logged in user (if any)
export const fetchUser = () => async (dispatch) => {
  modifiedAxios
    .get('/auth/user')
    .then((res) => {
      dispatch({ type: FETCH_USER, payload: res.data });
    })
    .catch((reason) => {
      dispatch({ type: FETCH_USER, payload: '' });
    });
};

// Log the user in
export const loginUser = () => async (dispatch) => {
  const res = await modifiedAxios.post('/auth/login');
  dispatch({ type: LOGIN_USER, payload: res.data });
};

// Fetch the current logged in user (if any)
export const updateUser = (data) => {
  return { type: UPDATE_USER, payload: data };
};

// // Log the user out
// export const logoutUser = () => async (dispatch) => {
//   const res = await modifiedAxios.get('/auth/logout').then((res) => (window.location.href = '/'));
//   dispatch({ type: LOGOUT_USER, payload: res.data });
// };
